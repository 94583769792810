import './App.css';

function App() {

  const hostname = window.location.hostname;

  return (
    <div className="wrap">
    <div className="domain">
            <h1 id="site_domain">Skytab Innovations</h1>
    </div>
    <div className="under-construction">
            <p>We're just getting ready, check back soon.</p>
    </div>
</div>
  );
}

export default App;
